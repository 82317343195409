$dark-gray: #222;
$light-gray: #444;
$amaranth: #eb5757;
$purple: #bb6bd9;
$light-blue: #56ccf2;

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
  user-select: none;
  background: #27142d;
  scrollbar-width: thin;
  font-family: "Rubik", sans-serif;
}

body {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 12px;
    position: absolute;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: $light-gray;
    background-clip: padding-box;
    border: 3px solid rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

* {
  box-sizing: border-box;
}
